import React from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { Header, Footer, Modal } from "./index";
import SimpleReactValidator from "simple-react-validator";
import "simple-react-validator/dist/locale/es.js";
import configData from "../config.json";
import Swal from "sweetalert2/";
import { lettersOnly, noSpaces, isNumber } from "./js/common";

const modalRoot = document.getElementsByTagName("body")[0];
let baseUrl = configData.SERVER_URL;
let emailREST = configData.EmailREST;
let passwordREST = configData.PasswordREST;

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      nombre: "",
      celular: "",
      mensaje: "",
      showModal: false,
    };

    this.validator = new SimpleReactValidator({ locale: "es" });
  }

  componentDidMount() {
    this.getToken();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        showModal: true,
      });

      fetch(baseUrl + "/contact", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: this.state.token,
        },
        body: JSON.stringify({
          Email: this.state.email,
          FullName: this.state.nombre,
          MobilePhone: this.state.celular,
          Message: this.state.mensaje,
	  Country: 'honduras'
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then((res) => {
          this.setState({
            showModal: false,
          });

          Swal.fire({
            text: res.data,
            icon: res.status === 400 ? "error" : "success",
          });

          this.setState({
            email: "",
            nombre: "",
            celular: "",
            mensaje: "",
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  getToken = () => {
    fetch(baseUrl + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({
        Email: emailREST,
        Password: passwordREST,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          token: "Bearer " + data,
        });
      })
      .catch((error) => console.error(error));
  };

  handleValueChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const showModal = this.state.showModal;

    return (
      <>
        {ReactDOM.createPortal(
          <Modal style={{ opacity: showModal ? "1" : "0" }} />,
          modalRoot
        )}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Puntos frescos</h3>
              <h1>Contacto</h1>
            </section>
          </header>
          <div className="content-wrap">
            <section className="content" style={{ position: "relative" }}>
              <div
                className="icon bg"
                style={{ margin: "0px auto", display: "block" }}>
                <img
                  src="/img/b_icono_contacto.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                  alt="icono contacto"
                />
              </div>
              <section className="info" style={{ position: "relative" }}>
                <header>
                  <h2>¿Tienes dudas o surgió un problema?</h2>
                  <h4 className="serif">
                    Escríbenos y te responderemos lo antes posible.
                  </h4>
                </header>
                <ul className="icon-list">
                  <li>
                    <a href="mailto:consultas@loymark.com">
                      <i className="icon icon-Email"></i>
                      <span>consultas@loymark.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/AquiFrescos" target="_blank" rel="noopener noreferrer">
                      <i className="icon icon-Link"></i>
                      <span>Facebook</span>
                    </a>
                  </li>
                </ul>
                <Link
                  to="/faqs"
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img
                    src="/img/back.svg"
                    style={{ width: "30px" }}
                    alt="back"
                  />
                </Link>
                <Link
                  to="/que-es"
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img
                    src="/img/next.svg"
                    style={{ width: "30px" }}
                    alt="next"
                  />
                </Link>
              </section>
              <section className="form-inline">
                <p>
                  Completa este formulario para enviar un mensaje directamente a
                  nuestro equipo de atención al cliente y poder resolver
                  cualquier inquietud que tengas. Nosotros estaremos en contacto
                  lo antes posible.
                </p>
                <p
                  style={{ display: "none", color: "#F4C400" }}
                  className="infoadicional">
                  Es posible que necesitemos información adicional o no podamos
                  localizarte en tu correo.
                  <br />
                  Nuestra vía principal es por correo electrónico.
                </p>
                <form
                  noValidate
                  onSubmit={this.handleSubmit}
                  className="form ambiance-html-form">
                  <div className="form-group">
                    <input
                      name="nombre"
                      id="nombre"
                      type="text"
                      placeholder="Nombre"
                      maxLength="50"
                      value={this.state.nombre}
                      onChange={(e) => this.handleValueChange(e)}
                      onKeyPress={(e) => lettersOnly(e)}
                      style={{ width: "98%", margin: "1% auto" }}
                    />
                    {this.validator.message(
                      "nombre",
                      this.state.nombre,
                      "required|max:75",
                      { className: "text-danger" }
                    )}
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <input
                        className="full-border"
                        name="celular"
                        id="celular"
                        maxLength="8"
                        type="tel"
                        placeholder="Celular"
                        value={this.state.celular}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => isNumber(e,8)}
                      />
                      {this.validator.message(
                        "celular",
                        this.state.celular,
                        "required|max:8",
                        { className: "text-danger" }
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        className="full-border"
                        name="email"
                        id="email"
                        type="email"
                        maxLength="50"
                        placeholder="Correo electrónico"
                        value={this.state.email}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => noSpaces(e)}
                      />
                      {this.validator.message(
                        "email",
                        this.state.email,
                        "required|max:50|email",
                        { className: "text-danger" }
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      rows="10"
                      cols="40"
                      value={this.state.mensaje}
                      onChange={(e) => this.handleValueChange(e)}
                      name="mensaje"
                      id="body"
                      placeholder="Mensaje"></textarea>
                    {this.validator.message(
                      "mensaje",
                      this.state.mensaje,
                      "required|max:5000",
                      { className: "text-danger" }
                    )}
                  </div>
                  <button
                    onSubmit={this.handleSubmit}
                    className="btn"
                    style={{
                      background: "#F4C400",
                      color: "#000000",
                      padding: "10px 30px",
                    }}>
                    Enviar
                  </button>
                </form>
              </section>
            </section>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
