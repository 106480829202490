import React from "react";
import ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import { Header, Footer, Modal } from "./index";
import Swal from "sweetalert2/";
import SimpleReactValidator from "simple-react-validator";
import "simple-react-validator/dist/locale/es.js";
import { DatePicker } from "@y0c/react-datepicker";
import "./css/styles.css";
import "./css/calendar.scss";
import configData from "../config.json";
import { lettersOnly, noSpaces, isNumber, alphanumeric } from "./js/common";

import "dayjs/locale/es";
import dayjs from "dayjs";

const modalRoot = document.getElementsByTagName("body")[0];
let baseUrl = configData.SERVER_URL;
let emailREST = configData.EmailREST;
let passwordREST = configData.PasswordREST;

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      identificacion: "",
      nombre: "",
      apellido1: "",
      apellido2: "",
      email: "",
      celular: "",
      genero: "",
      fechaNacimiento: new Date().setFullYear(new Date().getFullYear() - 18),
      showModal: false,
      redirect: false,
      walletURL: "",
    };

    this.validator = new SimpleReactValidator({ locale: "es" });
  }

  componentDidMount() {
    this.getToken();
  }

  handleValueChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleValueChangeDate = (val) => {
    this.setState({
      fechaNacimiento: val,
    });
  };

  handleValueChangeGender = (val) => {
    this.setState({
      genero: val.target.value,
    });
  };

  getToken = () => {
    fetch(baseUrl + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({
        Email: emailREST,
        Password: passwordREST,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          token: "Bearer " + data,
        });
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        showModal: true,
      });

      fetch(baseUrl + "/account", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          Authorization: this.state.token,
        },
        body: JSON.stringify({
          LoyaltyProgramID: 10,
          AccountNumber: this.state.identificacion,
          FirstName: this.state.nombre,
          LastName: this.state.apellido1,
          SecondLastName: this.state.apellido2,
          Email: this.state.email,
          MobilePhone: this.state.celular,
          Gender: this.state.genero,
          BirthDate: new Date(this.state.fechaNacimiento).toJSON(),
          Country: "honduras",
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then((res) => {
          this.setState({
            showModal: false,
          });

          if (res.status !== 200) {
            Swal.fire({
              text: res.data.replace(/['"]+/g, ""),
              icon: "error",
            });
          } else {
            console.log(res.data);
            this.setState({
              redirect: true,
              walletURL: res.data,
            });
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const showModal = this.state.showModal;
    const redirect = this.state.redirect;
    const walletURL = this.state.walletURL;

    return (
      <>
        {redirect ? (
          <Redirect
            to={{
              pathname: "/wallet",
              state: {
                url: walletURL,
                accountNumber: this.state.identificacion,
              },
            }}
          />
        ) : (
          " "
        )}

        {ReactDOM.createPortal(
          <Modal style={{ opacity: showModal ? "1" : "0" }} />,
          modalRoot
        )}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Puntos frescos</h3>
              <h1>Inscribirme</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0 auto", display: "block" }}>
                <img
                  src="/img/b_icono_inscribirse.svg"
                  alt="icono inscribirse"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                />
              </div>
              <section style={{ position: "relative" }}>
                <header>
                  <h2>¡Solo faltas tú!</h2>
                  <h4 className="serif">
                    Ingresa tus datos y verifica tu correo para completar el
                    proceso.
                  </h4>
                </header>
                <form
                  className="form ambiance-html-form"
                  noValidate
                  onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group">
                      <input
                        name="identificacion"
                        type="text"
                        placeholder="Número de identificación"
                        maxLength="15"
                        value={this.state.identificacion}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => alphanumeric(e)}
                      />
                      <i className="icon icon-User"></i>
                      {this.validator.message(
                        "identificación",
                        this.state.identificacion,
                        "required|max:15",
                        { className: "text-danger" }
                      )}
                    </div>
                    <hr />
                    <div className="form-group">
                      <input
                        name="nombre"
                        type="text"
                        maxLength="50"
                        placeholder="Nombre"
                        value={this.state.nombre}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => lettersOnly(e)}
                      />
                      <i className="icon icon-User"></i>
                      {this.validator.message(
                        "nombre",
                        this.state.nombre,
                        "required|max:50",
                        { className: "text-danger" }
                      )}
                    </div>
                    <hr />
                    <div className="form-group">
                      <input
                        name="apellido1"
                        type="text"
                        maxLength="50"
                        placeholder="Primer Apellido"
                        value={this.state.apellido1}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => lettersOnly(e)}
                      />
                      {this.validator.message(
                        "apellido paterno",
                        this.state.apellido1,
                        "required|max:50",
                        { className: "text-danger" }
                      )}
                    </div>
                    <hr />
                    <div className="form-group">
                      <input
                        name="apellido2"
                        type="text"
                        maxLength="50"
                        placeholder="Segundo Apellido"
                        value={this.state.apellido2}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => lettersOnly(e)}
                      />
                      {this.validator.message(
                        "apellido materno",
                        this.state.apellido2,
                        "required|max:50",
                        { className: "text-danger" }
                      )}
                    </div>
                    <hr />
                    <div className="form-group">
                      <input
                        className="full-border"
                        name="email"
                        id="email"
                        type="email"
                        maxLength="50"
                        placeholder="Correo electrónico"
                        value={this.state.email}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => noSpaces(e)}
                      />
                      <i className="icon icon-Email"></i>
                      {this.validator.message(
                        "correo electrónico",
                        this.state.email,
                        "required|max:50|email",
                        { className: "text-danger" }
                      )}
                      <p>
                        * Recibirás un correo de confirmación con tu número de
                        tarjeta.
                      </p>
                    </div>
                    <hr />
                    <div className="form-group">
                      <input
                        name="celular"
                        id="celular"
                        type="tel"
                        maxLength="8"
                        placeholder="Celular"
                        value={this.state.celular}
                        onChange={(e) => this.handleValueChange(e)}
                        onKeyPress={(e) => isNumber(e, 8)}
                      />
                      <i className="icon icon-Phone"></i>
                      {this.validator.message(
                        "celular",
                        this.state.celular,
                        "required|max:8",
                        { className: "text-danger" }
                      )}
                    </div>
                    <hr />
                    <div className="form-group">
                      <DatePicker
                        initialDate={new dayjs().subtract(18, "year")}
                        disableDay={(date) =>
                          date > new dayjs().subtract(18, "year")
                            ? new dayjs().subtract(18, "year")
                            : ""
                        }
                        onChange={this.handleValueChangeDate}
                        selected={this.state.fechaNacimiento}
                        dateFormat="YYYY/MM/DD"
                      />
                      <i
                        className="icon icon-Calendar"
                        style={{ top: "36px" }}></i>
                    </div>
                    <hr />
                    <div
                      onChange={this.handleValueChangeGender}
                      className="form-group"
                      style={{ paddingTop: "15px" }}>
                      <label style={{ paddingRight: "15px" }}>
                        <input type="radio" name="sexo" value="M" /> Masculino
                      </label>
                      <label>
                        <input type="radio" name="sexo" value="F" /> Femenino
                      </label>
                    </div>
                    <hr />
                    <div className="form-group" style={{ paddingTop: "0px" }}>
                      <br />
                      <label>
                        <input type="checkbox" name="acepto" /> He leído el &nbsp;
                        <a href="https://cargillhn.loymark.com/Reglamento.pdf" target="_blank">reglamento</a> de puntos frescos
                      </label>
                    </div>
                    <div className="form-group" style={{ paddingTop: "0px" }}>
                      <br />
                      <label>
                        <input type="checkbox" name="acepto" /> He leído la &nbsp;
                        <a href="https://cargillhn.loymark.com/AvisoPrivacidad.pdf" target="_blank">politica de privacidad</a> de puntos frescos y estoy de acuerdo otorgo mi consentimiento para el tratamiento de mis datos personales.
                      </label>
                    </div>
                    <hr />
                    <Link to="/recuperar" className="serif">
                      Recuperar wallet
                    </Link>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <button
                      onSubmit={this.handleSubmit}
                      className="btn"
                      style={{
                        background: "#F4C400",
                        color: "#000000",
                        padding: "10px 30px",
                      }}>
                      Enviar
                    </button>
                  </div>
                </form>
                <Link
                  to="/mecanica"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img src="/img/back.svg" style={{ width: "30px" }} alt="" />
                </Link>
                <Link
                  to="/consultar-puntos"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "-30px",
                    transform: "translate(0%, 0%)",
                  }}>
                  <img src="/img/next.svg" style={{ width: "30px" }} alt="" />
                </Link>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
