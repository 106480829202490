import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";

export default class About extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div id="wrapper">
          <main>
            <header className="detail">
              <Link to="/" className="back" data-transition="slide-from-top">
                <h1>back</h1>
              </Link>
              <section>
                <h3 className="badge">Puntos frescos</h3>
                <h1>¿Qué son puntos frescos?</h1>
              </section>
            </header>
            <div className="content-wrap">
              <div className="content">
                <div
                  className="icon bg"
                  style={{ margin: "0 auto", display: "block" }}>
                  <img
                    src="/img/b_icono_que_es.svg"
                    style={{
                      width: "50px",
                      paddingLeft: "5px",
                      paddingTop: "4px",
                    }}
                    alt="icono que es"
                  />
                </div>
                <section style={{ position: "relative" }}>
                  <header>
                    <h2>¡Bienvenido!</h2>
                    <h4 className="serif">
                    Serás parte de nuestro programa donde premiamos tu lealtad por la compra de nuestros productos de Pollo, 
                    Embutidos y otras carnes.
                    </h4>
                  </header>
                  <section>
                    <p>
                    Completa tu registro y comienza a acumular puntos en cada visita. 
                    Estos puntos podrás canjearlos por productos de nuestras marcas Cargill, y obtener 
                    muchos más beneficios que tenemos para ti
                    </p>
                    <div align="center" style={{ margin: "0 auto" }}>
                      <Link
                        to="inscribirme"
                        data-transition="slide-left"
                        style={{ color: "000000" }}>
                        <h3
                          className="badge"
                          style={{ textAlign: "center", margin: "0 auto" }}>
                          ¡Regístrate!
                        </h3>
                      </Link>
                    </div>
                    <Link
                      to="/contacto"
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/back.svg"
                        style={{ width: "30px" }}
                        alt="back"
                      />
                    </Link>
                    <Link
                      to="/mecanica"
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/next.svg"
                        style={{ width: "30px" }}
                        alt="next"
                      />
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            <div data-remodal-id="modal">
              <i className="icon bg icon-CommentwithLines"></i>
              <button
                data-remodal-action="close"
                className="remodal-close"></button>
            </div>
          </main>
        </div>
        <Footer />
      </>
    );
  }
}
