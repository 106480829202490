import React from "react";

export default class Footer extends React.Component {
  componentDidMount() {
    /*let script = document.createElement("script");
    script.src = " https://code.jquery.com/jquery-3.6.0.slim.min.js";
    script.async = true;

    document.body.appendChild(script);*/

    let script = document.createElement("script");
    script.src = "/js/pack.js";
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <>
        <ul id="slideshow" data-speed="6000">
          <li>
            <img src="/img/slideshow/restaurant/1.jpg" alt="slideshow1" />
          </li>
          <li>
            <img src="/img/slideshow/restaurant/2.jpg" alt="slideshow2" />
          </li>
          <li>
            <img src="/img/slideshow/restaurant/3.jpg" alt="slideshow3" />
          </li>
          <li>
            <img src="/img/slideshow/restaurant/4.jpg" alt="slideshow4" />
          </li>
        </ul>
        <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="pswp__bg"></div>
          <div className="pswp__scroll-wrap">
            <div className="pswp__container">
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
            </div>
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                <div className="pswp__counter"></div>
                <button
                  className="pswp__button pswp__button--close"
                  title="Close (Esc)"></button>
                <button
                  className="pswp__button pswp__button--share"
                  title="Share"></button>
                <button
                  className="pswp__button pswp__button--fs"
                  title="Toggle fullscreen"></button>
                <button
                  className="pswp__button pswp__button--zoom"
                  title="Zoom in/out"></button>
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div className="pswp__share-tooltip"></div>
              </div>
              <button
                className="pswp__button pswp__button--arrow--left"
                title="Previous (arrow left)"></button>
              <button
                className="pswp__button pswp__button--arrow--right"
                title="Next (arrow right)"></button>
              <div className="pswp__caption">
                <div className="pswp__caption__center"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
