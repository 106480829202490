import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, About, Contact, Points, SignUp, Program, Faqs, Wallet, NotFound, Recovery } from './components';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
           <Route path="/" exact component={() => <Home /> } />
           <Route exact path="/inscribirme"><SignUp /></Route>
           <Route exact path="/que-es" exact component={()=> <About />} />
           <Route exact path="/contacto" exact component={()=> <Contact/> } />
           <Route exact path="/consultar-puntos" exact component={()=> <Points/> } />
           <Route exact path="/mecanica" exact component={()=> <Program/> } />
           <Route exact path="/faqs" exact component={()=> <Faqs/> } />
           <Route exact path="/wallet" render={props=> <Wallet/> } />
           <Route exact path="/recuperar" render={() => <Recovery/> } />
           <Route component={() => <NotFound/ >} />
       </Switch>
      </Router>
    </div>  
  );
}

export default App;
