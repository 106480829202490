import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";
import { HashLink } from "react-router-hash-link";

export default class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "programa",
    };
  }

  handleValueChange = (val) => {
    this.setState({
      show: val.currentTarget.id,
    });
  };

  render() {
    let show = this.state.show;
    return (
      <>
        <Header />
        <div id="wrapper">
          <main>
            <header className="detail">
              <Link to="/" className="back" data-transition="slide-from-top">
                <h1>back</h1>
              </Link>
              <section>
                <h3 className="badge">Puntos frescos</h3>
                <h1>Preguntas Frecuentes</h1>
              </section>
            </header>
            <div className="content-wrap">
              <div className="content">
                <div
                  className="icon bg"
                  style={{ margin: "0 auto", display: "block" }}>
                  <img
                    src="/img/b_icono_aclara.svg"
                    style={{
                      width: "50px",
                      paddingLeft: "5px",
                      paddingTop: "4px",
                    }}
                    alt=""
                  />
                </div>
                <section style={{ position: "relative" }}>
                  <header>
                    <h2>Todas tus dudas resueltas</h2>
                    <h4 className="serif">
                      Si tu duda no esta aquí, por favor escríbenos
                    </h4>
                  </header>
                  <header className="with-subnav">
                    <ul>
                      <li
                        id="programa"
                        className={`btn ${
                          this.state.show === "programa" ? "active" : ""
                        }`}
                        onClick={(val) => this.handleValueChange(val)}>
                        <HashLink
                          to="/faqs#programa"
                          data-transition="slide-left">
                          <h4>Sobre el Programa</h4>
                        </HashLink>
                      </li>
                      <li
                        id="puntos"
                        className={`btn ${
                          this.state.show === "puntos" ? "active" : ""
                        }`}
                        onClick={(val) => this.handleValueChange(val)}>
                        <HashLink
                          to="/faqs#puntos"
                          data-transition="slide-left">
                          <h4>Sobre los puntos</h4>
                        </HashLink>
                      </li>
                    </ul>
                  </header>
                  <section>
                    {show === "programa" && (
                      <div id="programa">
                        <h3>¿Qué son los “PUNTOS FRESCOS?</h3>
                        <p>
                        Son los puntos redimibles por productos que acumula el TITULAR de la 
                        TARJETA DE CLIENTE FRECUENTE al realizar sus compras en las en Aquí + Frescos y Pollos y más autorizados.
                        </p>
                        <hr />
                        <h3>¿Quién es el organizador de este programa de lealtad?</h3>
                        <p>
                          Cargill de Honduras con RTN 05019001049230 es la organizadora de la promoción y única responsable de la misma ante
                          cualquier tercero.
                        </p>
                        <hr />
                        <h3>¿Quienes pueden afiliarse al programa de lealtad?</h3>
                        <p>
                        Podrán afiliarse únicamente personas naturales mayores de edad y con documento de identificación válido, 
                        vigente y reconocido por el ordenamiento jurídico de Honduras. 
                        Es necesario que el TITULAR cuente con un teléfono inteligente apto para instalar el WALLET con acceso a internet.
                        </p>
                        <hr />
                        <h3>¿Cuáles beneficios obtengo al ser parte del programa de lealtad “PUNTOS FRESCOS?</h3>
                        <p>
                        Los clientes que se registren en el programa de lealtad “PUNTOS FRESCOS” podrán obtener:
                        </p>
                        <p>
                        - Tarjeta de cliente frecuente para acumular “PUNTOS FRESCOS” que podrán ser redimidos por productos de las marcas: Pollo Norteño, Delicia, Kimby, Castillo del Roble.
                        </p>
                        <p>
                        - Participar en promociones exclusivas.
                        </p>
                        <p>
                        - Recibir información de nuevos productos, descuentos y eventos.
                        </p>
                        <hr />
                        <h3>¿Qué pasa si me desinscribo del programa de lealtad?</h3>
                        <p>
                        Los PUNTOS FRESCOS que no hayas redimido, serán eliminados, y no podrán ser reclamados en producto ni efectivo.
                        </p>
                      </div>
                    )}
                    {show === "puntos" && (
                      <div id="puntos">
                        <h3>¿Cómo acumulo apuntos?</h3>
                        <p>Por la compra de productos en las agencias que se encuentran dentro del programa, todos los productos acumulan.</p>
                        <hr />
                        <h3>¿Donde los puedo acumular?</h3>
                        <p>En nuestras salas de ventas</p>
                        <ul  style={{ color: "white" }}>
                              <li>San Pedro Sula</li>
                                    <ul style={{ color: "white" }}> 
                                              <li>Aquí + Frescos Blvd. Micheletti</li>
                                              <li>Aquí + Frescos Plaza Uno</li>
                                    </ul>
                              <li>Tegucigalpa</li>
                                    <ul style={{ color: "white" }}> 
                                              <li>Aquí + Frescos Plaza Premier</li>
                                              <li>Aquí + Frescos América</li>
                                    </ul>
                        </ul>
                        <hr />
                        <h3>¿Donde consulto los puntos que tengo?</h3>
                        <p>Los puedes revisar en el micrositio en “Consulta de puntos” o en el “Wallet”.</p>
                        <hr />
                        <h3>No aparecen mis puntos, ¿qué hago?</h3>
                        <p>Los puntos se visualizan en un plazo de 3 horas después de realizada la compra, en caso de no visualizarlos 
                           puede acercarse a la agencia mas cercana y consultar, o escribir a consultas@loymark.com</p>
                        <hr />
                        <h3>¿Cómo canjeo mis puntos?</h3>
                        <p>Los puntos se pueden canjear en los Aquí + Frescos y Pollo y Más autorizados, a partir de 30 puntos acumulados.</p>
                      </div>
                    )}

                    <div align="center" style={{ margin: "0 auto" }}>
                      <Link
                        to="/contacto"
                        data-transition="slide-left"
                        style={{ color: "#000000" }}>
                        <h3 className="badge">Escríbenos</h3>
                      </Link>
                    </div>
                    <Link
                      to="/consultar-puntos"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/back.svg"
                        style={{ width: "30px" }}
                        alt=""
                      />
                    </Link>
                    <Link
                      to="/contacto"
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "-30px",
                        transform: "translate(0%, 0%)",
                      }}>
                      <img
                        src="/img/next.svg"
                        style={{ width: "30px" }}
                        alt=""
                      />
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            <div data-remodal-id="modal">
              <i className="icon bg icon-CommentwithLines"></i>
              <button
                data-remodal-action="close"
                className="remodal-close"></button>
            </div>
          </main>
        </div>
        <Footer />
      </>
    );
  }
}
