import React from "react";

export default class Header extends React.Component {
  render() {
    return (
      <>
        <div className="shadow"></div>
        <div id="splash">
          <div className="loader">
            <img className="splash-logo" src="/img/logo/logos.png" alt="logo" />
            <div className="line"></div>
          </div>
        </div>
      </>
    );
  }
}
