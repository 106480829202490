import React from "react";
import { Link } from 'react-router-dom';
import './css/404.css';

export default class NotFound extends React.Component {
  render() {
    return (
      <>
      <div className="container">
    <div className="heading">
      <h1>404</h1>
    </div>

    <div className="description">
      <h2>Página no encontrada</h2>
      <p>
        Lo sentimos, la página a la que estás intentando acceder <br /> no
        existe o no se encuentra disponible.
      </p>
      <Link to="/" className="btn btn-primary">
        Regresar a inicio
      </Link>
    </div>
  </div>;
      </>
    )
  }
};
