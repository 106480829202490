import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer, Navigation } from "./index";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Header />
          <main>
            <header>
              <Link to="/" className="logo">
                <h1>Programa de Lealtad</h1>
                <img src="/img/logo/logos.png" alt="logo1" />
              </Link>
            </header>
            <Navigation />
          </main>
        <Footer />
      </>
    );
  }
}
