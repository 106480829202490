import React from "react";
import ReactDOM from "react-dom";
import { Link, Redirect } from "react-router-dom";
import { Header, Footer, Modal } from "./index";
import Swal from "sweetalert2/";
import SimpleReactValidator from "simple-react-validator";
import "simple-react-validator/dist/locale/es.js";
import { DatePicker } from "@y0c/react-datepicker";
import "./css/styles.css";
import "./css/calendar.scss";
import configData from "../config.json";
import { noSpaces } from "./js/common";

import "dayjs/locale/es";
import dayjs from "dayjs";

const modalRoot = document.getElementsByTagName("body")[0];
let baseUrl = configData.SERVER_URL;
let emailREST = configData.EmailREST;
let passwordREST = configData.PasswordREST;

export default class Recovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      identificacion: "",
      fechaNacimiento: new Date().setFullYear(new Date().getFullYear() - 18),
      showModal: false,
      redirect: false,
      walletURL: "",
    };

    this.validator = new SimpleReactValidator({ locale: "es" });
  }

  componentDidMount() {
    this.getToken();
  }

  handleValueChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleValueChangeDate = (val) => {
    this.setState({
      fechaNacimiento: val,
    });
  };

  handleValueChangeGender = (val) => {
    this.setState({
      genero: val.target.value,
    });
  };

  getToken = () => {
    fetch(baseUrl + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({
        Email: emailREST,
        Password: passwordREST,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          token: "Bearer " + data,
        });
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        showModal: true,
      });

      fetch(
        baseUrl +
          "/account/recovery/" +
          this.state.email +
          "/" +
          new Date(this.state.fechaNacimiento).toJSON() +
          "/" +
          10,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            Authorization: this.state.token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then((res) => {
          this.setState({
            showModal: false,
          });

          if (res.status !== 200) {
            Swal.fire({
              text: "Los datos ingresados no coinciden con ningún registro",
              icon: "error",
            });
          } else {debugger;
            console.log(res.data);
            console.log(res.data.accountNumber);
            console.log(res.data.walletURL);
            this.setState({
              redirect: true,
              walletURL: res.data.walletURL,
              identificacion: res.data.accountNumber
            });
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const showModal = this.state.showModal;
    const redirect = this.state.redirect;
    const walletURL = this.state.walletURL.replace(/['"]+/g, '');
    return (
      <>
        {redirect ? (
          <Redirect
            to={{
              pathname: "/wallet",
              state: {
                url: walletURL,
                accountNumber: this.state.identificacion,
              },
            }}
          />
        ) : (
          " "
        )}

        {ReactDOM.createPortal(
          <Modal style={{ opacity: showModal ? "1" : "0" }} />,
          modalRoot
        )}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Programa de Lealtad</h3>
              <h1>Recuperar Wallet</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0px auto", display: "block" }}>
                <img
                  src="/img/b_icono_consulta.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                  alt=""
                />
              </div>
              <section style={{ position: "relative" }}>
                <header>
                  <h2>Recuperar wallet</h2>
                  <h4 className="serif">
                    Sólo ingresa tu correo electrónico y tu fecha de nacimiento
                  </h4>
                </header>
                <section>
                  <form
                    className="form ambiance-html-form"
                    noValidate
                    onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="form-group">
                        <input
                          className="full-border"
                          name="email"
                          id="email"
                          type="email"
                          maxLength="50"
                          placeholder="Correo electrónico"
                          value={this.state.email}
                          onChange={(e) => this.handleValueChange(e)}
                          onKeyPress={(e) => noSpaces(e)}
                        />
                        <i className="icon icon-Email"></i>
                        {this.validator.message(
                          "correo electrónico",
                          this.state.email,
                          "required|max:50|email",
                          { className: "text-danger" }
                        )}
                      </div>
                      <hr />
                      <div className="form-group">
                        <DatePicker
                          initialDate={new dayjs().subtract(18, "year")}
                          disableDay={(date) =>
                            date > new dayjs().subtract(18, "year")
                              ? new dayjs().subtract(18, "year")
                              : ""
                          }
                          onChange={this.handleValueChangeDate}
                          selected={this.state.fechaNacimiento}
                          dateFormat="YYYY/MM/DD"
                        />
                        <i
                          className="icon icon-Calendar"
                          style={{ top: "36px" }}></i>
                      </div>
                      <hr />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <br />
                      <button
                        onSubmit={this.handleSubmit}
                        className="btn"
                        style={{
                          background: "#F4C400",
                          color: "#000000",
                          padding: "10px 30px",
                        }}>
                        Enviar
                      </button>
                    </div>
                  </form>
                </section>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
