import React from 'react';

export default class Modal extends React.Component {
render() {
    return (
      <>
        <div className="loader-box" style={this.props.style}>
          <div className="loader-5">
            <span></span>
          </div>
        </div>
      </>
    );
  }
}
