import React from "react";
import { Link } from "react-router-dom";
import { Header, Footer } from "./index";

export default class Program extends React.Component {
  render() {
    return (
      <>
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Puntos frescos</h3>
              <h1>Mecánica del Programa</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0px auto", display: "block" }}>
                <img
                  src="/img/b_icono_mecanica.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                  alt=""
                />
              </div>
              <section style={{ position: "relative" }}>
                <header>
                  <h2>Mecánica del programa</h2>
                  <h4 className="serif">
                    ¡Conoce cómo acumular puntos y canjearlos!
                  </h4>
                </header>
                <section>
                  <h2>Inscríbete</h2>
                  <p>
                    Ingresa desde tu celular a la página web www.puntoshn.frescuraquesenota.com
                  </p>
                  <p>
                    Ingresa al botón "Regístrate en el programa Puntos Frescos" y registra tus datos personales.
                  </p>
                  <p>
                   Lee el reglamento y términos de privacidad y dale “check” a las casillas indicando que estás de acuerdo.
                  </p>
                  <p>
                   Cuando finalicés de llenar el formulario, dale click a la botón ENVIAR y espere unos segundos.
                  </p>
                  <p>
                  Y finalmente para guardar tu tarjeta de cliente frecuente en el Wallet, 
                  debes presionar el botón: “Add to Apple Wallet” si tu celular es iphone ó “Añadir a WalletPasses” si tu celular es Android
                  </p>
                  <h2>Acumula Puntos</h2>
                  <p>
                   Cada vez que realices compras al detalle en Aquí + Frescos y Pollos y más autorizados, 
                   presenta tu tarjeta digital de cliente frecuente antes de realizar el pago de la misma. 
                  </p>
                  <p>
                   El encargado(a) de la caja escaneará tu tarjeta de cliente frecuente para que se registren
                   los puntos obtenidos. (los verás actualizados en tu tarjeta, en un tiempo máximo de 3 horas)
                  </p>
                  <h2>Canje de Puntos</h2>
                  <p>
                   L30 de compra equivalen a 1 punto, cuando tengas mínimo 30 puntos en tu wallet puedes comenzar a canjear estos puntos por productos
                   de nuestras marcas: Pollo Norteño, Embutidos Delicia, Kimby y Castillo del Roble.
                 </p>
                  <p>
                  Solamente debes mostrar al encargado de caja tus puntos y el producto que vas a canjear.
                  </p>
                  <p>
                  Si tu producto excede en precio a tus puntos disponibles debes pagar la diferencia.
                  </p>
                  <div align="center" style={{ margin: "0px auto" }}>
                    <Link
                      to="/inscribirme"
                      data-transition="slide-left"
                      style={{ color: "#000000" }}>
                      <h3 className="badge">¡Regístrate!</h3>
                    </Link>
                  </div>
                  <Link
                    to="/que-es"
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "-30px",
                      transform: "translate(0%, 0%)",
                    }}>
                    <img src="/img/back.svg" style={{ width: "30px" }} alt="" />
                  </Link>
                  <Link
                    to="/inscribirme"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "-30px",
                      transform: "translate(0%, 0%)",
                    }}>
                    <img src="/img/next.svg" style={{ width: "30px" }} alt="" />
                  </Link>
                </section>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
