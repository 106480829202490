import React from "react";
import { Header, Footer } from "./index";
import { Link, Redirect, withRouter } from "react-router-dom";

class Wallet extends React.Component {
  render() {
    const walletURL =
      this.props.location.state !== undefined
        ? this.props.location.state.url
        : "";
    const accountNumber =
      this.props.location.state !== undefined
        ? this.props.location.state.accountNumber
        : "";

    return (
      <>
        {walletURL === "" ? <Redirect to="/inscribirme" /> : ""}
        <Header />
        <main>
          <header className="detail">
            <Link to="/" className="back" data-transition="slide-from-top">
              <h1>back</h1>
            </Link>
            <section>
              <h3 className="badge">Programa de Lealtad</h3>
              <h1>Descarga tu Wallet</h1>
            </section>
          </header>
          <div className="content-wrap">
            <div className="content">
              <div
                className="icon bg"
                style={{ margin: "0 auto", display: "block" }}>
                <img
                  src="/img/b_icono_consulta.svg"
                  style={{
                    width: "50px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                  }}
                  alt=""
                />
              </div>
              <section>
                <header>
                  <h2>¡Registro exitoso!</h2>
                  <h4 className="serif">Aquí esta tu número de tarjeta</h4>
                </header>
                <form
                  className="form ambiance-html-form"
                  action="#"
                  method="post"
                  id="reservation-form">
                  <div className="row">
                    <div className="form-group">
                      <input
                        name="puntos"
                        id="puntos"
                        type="text"
                        readOnly=""
                        value={accountNumber}
                        style={{ textAlign: "center" }}
                      />
                      <i className="icon icon-CreditCard"></i>
                    </div>
                  </div>
                </form>

                <br />

                <div className="wallet-div">
                  <h2 className="mb-4">Descarga tu tarjeta digital</h2>
                  <p>
                    Si tienes Android necesitas tener la aplicación
                    WALLETPASSES. Si tienes iOS, la aplicación WALLET ya viene
                    instalada.
                  </p>
                  <p>
                    Recuerda que tienes que realizar la descarga de tu tarjeta
                    digital desde tu smartphone.
                  </p>

                  <div className="figure-flex">
                    <figure>
                      <a href={walletURL}>
                        <img
                          className=""
                          src="/img/apple-wallet.png"
                          alt="Añadir a Apple Wallet"
                          width="194"
                          height="60"
                        />
                      </a>
                    </figure>

                    <figure>
                      <a href={walletURL}>
                        <img
                          className=""
                          src="/img/walletpasses.png"
                          alt="Añadir a WalletPasses"
                          width="194"
                          height="60"
                        />
                      </a>
                    </figure>
                  </div>

                  <p>Si aún no tienes Wallet Passes ¡descárgala sin costo!</p>

                  <figure>
                    <a href="https://play.google.com/store/apps/details?id=io.walletpasses.android&hl=es&gl=US" target="_blank">
                      <img
                        className=""
                        src="/img/google-play.png"
                        alt="Disponible en Google Play"
                        width="194"
                        height="60"
                      />
                    </a>
                  </figure>

                  <p>
                    Consulta nuestra Política de Privacidad <a href="https://cargillhn.loymark.com/AvisoPrivacidad.pdf">aquí</a>
                  </p>
                </div>
              </section>
            </div>
          </div>
          <div data-remodal-id="modal">
            <i className="icon bg icon-CommentwithLines"></i>
            <button
              data-remodal-action="close"
              className="remodal-close"></button>
            <h1></h1>
            <p></p>
            <div className="signature center"></div>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default withRouter(Wallet);
